import ng from 'angular';
import { FeePlanResponse } from 'components/feeplan-domain';
import acpConfirmDialogBoxTemplate from './templates/acp-feeplan-dialog-box-component.html';

export class AcpFeePlanConfirmDialogComponentCtrl
  implements nsUtils.NsComponentController {
  parsedFeePlan: FeePlanResponse;
  isRecurring = false;
  isPayAsYouGo = false;
  typeOfPlan: string;
  private feePlan: string;
  constructor(
    private $mdDialog: ng.material.IDialogService,
    private $mdBottomSheet: ng.material.IBottomSheetService
  ) {
    'ngInject';

    // If methods such as $onValue and $tie are needed:
    // nsComponentDecorator(this, this);
  }

  $onInit() {
    // Perform initialization here
    this.parsedFeePlan = JSON.parse(this.feePlan);
    this.isRecurring = this.parsedFeePlan.flags.some((key) => {
      return key === 'recurring';
    });
    this.isPayAsYouGo = this.parsedFeePlan.flags.some((key) => {
      return key === 'pay_as_you_go';
    });
    if (this.isPayAsYouGo && this.parsedFeePlan.months === 0) {
      this.typeOfPlan = '-pay-as-you-go';
    } else if (!this.isPayAsYouGo && this.parsedFeePlan.months === 0) {
      this.typeOfPlan = '-monthly-fee';
    } else if (
      this.parsedFeePlan.name.match(/Annual/g) &&
      this.parsedFeePlan.months !== 0
    ) {
      this.typeOfPlan = '-annual-fee';
    } else {
      this.typeOfPlan = '';
    }
  }

  cancel(): void {
    this.$mdDialog.cancel();
    this.$mdBottomSheet.cancel();
  }

  submit(): void {
    this.$mdDialog.hide();
    this.$mdBottomSheet.hide();
  }
}

export const acpFeePlanConfirmDialogComponent = {
  bindings: {
    feePlan: '@'
  }, // bindings advice: https://docs.angularjs.org/guide/component#component-based-application-architecture
  controller: AcpFeePlanConfirmDialogComponentCtrl,
  controllerAs: 'vm',
  require: {},
  template: acpConfirmDialogBoxTemplate
};
