import ng from 'angular';
import { acpFeePlanDomain } from 'components/feeplan-domain';
import acpCore from 'core';
import { acpFeePlanConfirmDialogComponent } from './acp-feeplan-dialog-box-component';
import { acpFeeplanTableComponent } from './acp-feeplan-table-component';
import acpFeePlanConfirmDialogBoxContent from './dialog-box-content.yml';
import acpFeeplanTableContent from './feeplan-table-content.yml';
import './styles/${theme}/core.scss';

const acpFeeplanTableModule = ng
  .module('acp.component.feeplan-table', [acpCore.name, acpFeePlanDomain.name])
  .component('acpFeeplanTableComponent', acpFeeplanTableComponent)
  .component('acpFeeplanConfirmDialog', acpFeePlanConfirmDialogComponent)
  .run((contentSectionCache) => {
    'ngInject';

    contentSectionCache.put(
      'components/feeplan-base/fee-table',
      acpFeeplanTableContent
    );
    contentSectionCache.put(
      'components/feeplan-base/dialog-box',
      acpFeePlanConfirmDialogBoxContent
    );
  });

// Re-export services / ctrls that may be imported from other modules to use for typing information
export { acpFeeplanTableModule };
