import { AcpBrand, AcpBrandingModel, AcpMedia } from 'core';

import {
  ACP_FEEPLAN_CONSTANTS,
  AcpFeeplanModel,
  AvailableFeePlansResponse,
  CurrentFeePlanResponse,
  FeePlanById,
  FeePlanData,
  FeePlanResponse,
  SubscribePlanRequest
} from 'components/feeplan-domain';
import acpFeeplanTableComponentTemplate from './templates/acp-feeplan-table-component.html';

export interface AcpConfigurationService {
  getBrand(): string;
}

export class AcpFeeplanTableComponentCtrl {
  public availableFeeplans: AvailableFeePlansResponse;
  public brand: string;
  public displayObj: FeePlanData[] = [];
  public selectedFeePlan: CurrentFeePlanResponse;
  public currentPlanId: number;
  public completeFeePlanById: string;
  public showError: boolean = false;
  public busy: boolean = false;
  public hasPremierEligible: boolean = false;
  public ACP_FEEPLAN_CONSTANTS = ACP_FEEPLAN_CONSTANTS;
  public branding: AcpBrand;
  public displayObjSkylight: FeePlanData[] = [];
  public isFeePlanChangeAllowed: boolean = false;
  public feePlanAndAnytimeAlertsWCAConfigEnabled: boolean = false;

  constructor(
    private acpMedia: AcpMedia,
    private acpFeeplanDomainModel: AcpFeeplanModel,
    private $mdDialog: ng.material.IDialogService,
    private $mdBottomSheet: ng.material.IBottomSheetService,
    private acpCoreDispatcher: any,
    private acpBrandingModel: AcpBrandingModel,
    private acpConfigurationService: AcpConfigurationService
  ) {
    'ngInject';
  }

  public $onInit() {
    this.brand = this.acpConfigurationService.getBrand();
    this.getAllAvailableFeeplans();
    this.getCurrentFeePlan();
    this.branding = this.acpBrandingModel.getBranding();
    this.acpFeeplanDomainModel
      .fetchPermissions()
      .then(this.updateFeePlanPermissions.bind(this));
  }

  public continue(id: number): void {
    if (this.busy) {
      return;
    }
    this.busy = true;
    const feePlanbyId: FeePlanResponse = this.acpFeeplanDomainModel.getFeeDetailsById(
      id,
      this.availableFeeplans
    );
    const selectedFeeObj: FeePlanById = {
      id: feePlanbyId.id,
      name: feePlanbyId.name,
      cost: feePlanbyId._cost,
      months: feePlanbyId.months,
      flags: feePlanbyId.flags
    };
    this.completeFeePlanById = JSON.stringify(selectedFeeObj);
    if (this.acpMedia('mobile')) {
      this.$mdBottomSheet
        .show({
          template: `<md-bottom-sheet><acp-feeplan-confirm-dialog fee-plan='${
            this.completeFeePlanById
          }'></acp-feeplan-confirm-dialog></md-bottom-sheet>`
        })
        .then(() => {
          this.subscribeFeePlan(id);
        }, () => (this.busy = false));
    } else {
      this.$mdDialog
        .show({
          template: `<acp-feeplan-confirm-dialog fee-plan='${
            this.completeFeePlanById
          }'></acp-feeplan-confirm-dialog>`,
          clickOutsideToClose: false
        })
        .then(() => {
          this.subscribeFeePlan(id);
        }, () => (this.busy = false));
    }
  }

  public premierEligibleContent(feePlan: FeePlanResponse) {
    return (
      feePlan._cost !== 0 && this.hasPremierEligible && feePlan.months === 0
    );
  }

  // Required for TSC. The real impl comes from `nsComponentDecorator(this, this)`
  // public $tiePermissions?(property: string, permissions: string[]);
  // public $onValue?<T>(signal: nsUtils.NsSignal<T>, listener: (data: T) => void);

  private async getAllAvailableFeeplans() {
    try {
      this.availableFeeplans = await this.acpFeeplanDomainModel.getAll();
      this.displayObj = this.acpFeeplanDomainModel.getPopulatedFeeData(
        this.availableFeeplans
      );
    } catch (e) {
      this.availableFeeplans = null;
      this.displayObj = null;
    }
  }

  private async getCurrentFeePlan() {
    try {
      this.selectedFeePlan = await this.acpFeeplanDomainModel.get();
      this.currentPlanId = this.selectedFeePlan.plan.id;

      // For Skylight Brand only
      if (this.brand === this.ACP_FEEPLAN_CONSTANTS.BRAND.SKYLIGHT) {
        this.displayObjSkylight = this.acpFeeplanDomainModel.getPopulatedDataSkylight(
          this.selectedFeePlan.plan
        );
      }
    } catch (e) {
      this.selectedFeePlan = null;
    }
  }

  private async subscribeFeePlan(id) {
    const planId: SubscribePlanRequest = { id };
    try {
      const response = await this.acpFeeplanDomainModel.subscribeNonExpence(
        planId
      );
      this.successHandler(response);
    } catch (error) {
      this.errorHandler(error);
    } finally {
      this.busy = false;
    }
  }

  private successHandler(response: any): void {
    this.acpCoreDispatcher.toast.show.emit({
      toastClass: 'success',
      content: 'fee-plan-updated:components/feeplan-base/fee-table',
      hideDelay: 4000
    });
    this.acpCoreDispatcher.feePlan.feePlanSubscribedSuccessful.emit();
    this.displayObj = [];
    this.acpFeeplanDomainModel.formattedFeePlanData = [];
    this.getAllAvailableFeeplans();
    this.getCurrentFeePlan();
  }

  private errorHandler(error: any): void {
    this.showError = true;
    this.displayObj = [];
    this.acpFeeplanDomainModel.formattedFeePlanData = [];
    this.getAllAvailableFeeplans();
    this.getCurrentFeePlan();
  }

  private updateFeePlanPermissions(permissions?: nsUtils.NsPermissionResult) {
    this.hasPremierEligible = permissions && permissions.hasPremierEligible;
    this.isFeePlanChangeAllowed =
      permissions && permissions.feePlanAndAnytimeAlertsWCAConfigEnabled
        ? permissions && permissions.isFeePlanChangeAllowed
        : permissions && !permissions.displayActivateCardCFPB;
  }
}

export const acpFeeplanTableComponent = {
  controller: AcpFeeplanTableComponentCtrl,
  controllerAs: 'vm',
  template: acpFeeplanTableComponentTemplate
};
